import React, { Fragment } from 'react'

import { Location } from '@reach/router'

import Helmet from 'react-helmet'

import config from 'config'

import root from 'utils/windowOrGlobal'
import isMobileDevice from 'utils/isMobileDevice'
import { getUrlParameter } from 'utils/parse-querystring'

import { FullPageLoader } from 'components/Loader'

const meta = {
  title: 'StayCircles Mobile - Search, Book, Stay with Friends',
  description:
    'StayCircles makes it easy to search, book and stay with friends as you travel for business or fun. Get the official StayCircles mobile app now.'
}

export default () => {
  return (
    <Location>
      {({ location }) => {
        sendGAEvent(location)
        appRedirect()

        return (
          <Fragment>
            <Helmet>
              <title>${meta.title}</title>

              <meta name='description' content={meta.description} />
              <meta
                name='image'
                content='https://res.cloudinary.com/staycircles/image/upload/f_auto,q_auto/v1542077900/assets/meta/Logo_White.png'
              />

              <meta itemProp='name' content={meta.title} />
              <meta itemProp='description' content={meta.description} />
              <meta
                itemProp='image'
                content='https://res.cloudinary.com/staycircles/image/upload/f_auto,q_auto/v1542077900/assets/meta/Logo_White.png'
              />

              <meta name='twitter:card' content='summary' />
              <meta name='twitter:title' content={meta.title} />
              <meta name='twitter:description' content={meta.description} />

              <meta name='og:title' content={meta.title} />
              <meta name='og:description' content={meta.description} />
              <meta
                name='og:image'
                content='https://res.cloudinary.com/staycircles/image/upload/f_auto,q_auto/v1542078118/assets/meta/Home.png'
              />
              <meta
                name='og:url'
                content='https://www.staycircles.com/downloadapp'
              />
              <meta name='og:site_name' content='StayCircles' />
              <meta name='og:type' content='website' />
            </Helmet>

            <FullPageLoader />
            {renderFacebookPixel()}
          </Fragment>
        )
      }}
    </Location>
  )
}

let eventTracked = false

function sendGAEvent (location) {
  if (eventTracked) return

  eventTracked = true

  if (!config.GANALYTICS_ID) return null

  if (root && typeof root.ga !== 'function') {
    console.log('analytics.js not found. Failed to send event!')
    return
  }

  const campaign = getUrlParameter(location, 'utm_campaign')
  root.ga('send', 'event', 'SC App Store Link', 'redirect', campaign)
}

function renderFacebookPixel () {
  if (config.ENV === 'production') {
    return (
      <Fragment>
        <script
          type='text/javascript'
          dangerouslySetInnerHTML={{
            __html: `
              !function(f,b,e,v,n,t,s)
              {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
              n.callMethod.apply(n,arguments):n.queue.push(arguments)};
              if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
              n.queue=[];t=b.createElement(e);t.async=!0;
              t.src=v;s=b.getElementsByTagName(e)[0];
              s.parentNode.insertBefore(t,s)}(window, document,'script',
              'https://connect.facebook.net/en_US/fbevents.js');
              fbq('init', '858165974377524');
              fbq('track', 'PageView');
            `
          }}
        />
        <noscript>
          <img
            height='1'
            width='1'
            style={{ display: 'none' }}
            src='https://www.facebook.com/tr?id=858165974377524&ev=PageView&noscript=1'
          />
        </noscript>
      </Fragment>
    )
  }
}

const appleDevice =
  root.navigator && /^(iPhone|iPad|iPod)/.test(root.navigator.platform)

function appRedirect () {
  const pageUrl = '/staycirclesapp'
  const appStoreUrl =
    'https://itunes.apple.com/us/app/staycircles/id1402291438?ls=1&mt=8'
  const playStoreUrl =
    'https://play.google.com/store/apps/details?id=com.staycircles.android'

  if (!isMobileDevice()) {
    root.location = pageUrl
  } else if (appleDevice) {
    root.location = appStoreUrl
  } else {
    root.location = playStoreUrl
  }
}
